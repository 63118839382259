
/*export const properties = {
    POST_API_GATEWAY_URL: "https://vpybjm6tp9.execute-api.eu-west-2.amazonaws.com/dev/update-race",
    GET_API_GATEWAY_URL: "https://vpybjm6tp9.execute-api.eu-west-2.amazonaws.com/dev/say-hello"
};
*/

export const properties = {
    POST_API_GATEWAY_URL: "https://api.f1allday.com/process-schedule",
    GET_API_GATEWAY_URL: "https://api.f1allday.com/process-schedule"
};


export const dataload_VM_Control = {
    POST_API_GATEWAY_URL: "https://api.f1allday.com/switch-dataload-vm",
    GET_API_GATEWAY_URL: "https://api.f1allday.com/switch-dataload-vm"
};

export const dataload_VM_EC2_REST = {
    POST_API_GATEWAY_URL: "http://www.dataload.f1allday.com:8080/",
    GET_API_GATEWAY_URL: "http://www.dataload.f1allday.com:8080/"
};