import { configureStore } from '@reduxjs/toolkit'
import selectedRaceReducer from './selectedRaceSlice'
import editModeReducer from './editModeSlice'
import vmStateReducer from './vmStateSlice'
import dataloadJobReducer from './dataloadJobSlice'
import dataloadYearReducer from './dataloadYearSlice'
import dataloadRoundReducer from './dataloadRoundSlice'

export default configureStore({
  reducer: {
    selectedRace: selectedRaceReducer,
    editMode: editModeReducer,
    vmControlState: vmStateReducer,
    dataloadJob: dataloadJobReducer,
    dataloadYear: dataloadYearReducer,
    dataloadRound: dataloadRoundReducer
  }
})