import NavBarClass from '../NavBarf1allday';
import vmState from '../data-sources/admin_vm_state.json';
import { Fragment, useEffect  } from 'react'

import store from './redux/adminpages/store'

import { Provider } from 'react-redux'
import {years, rounds, jobs} from './resource/admin-dataload-resources'

import { useSelector, useDispatch } from 'react-redux'
import {
    setVmState,
    selectVmState,
    updateVmControlSwitchIsDisabled,
    selectIsVmControlDisabled
} from './redux/adminpages/vmStateSlice'
import {
    resetDataloadJobState,
    setDataloadJobState,
    selectDataloadJobState
} from './redux/adminpages/dataloadJobSlice'
import {
    resetDataloadYearState,
    setDataloadYearState,
    selectDataloadYearState
} from './redux/adminpages/dataloadYearSlice'
import {
    resetDataloadRoundState,
    setDataloadRoundState,
    selectDataloadRoundState
} from './redux/adminpages/dataloadRoundSlice'
import {
    callApiPostDataload, 
    callApiGetDataload, 
    callJOBApiPostDataload
} from './middleware/callDataloadApi';


export default function AdminDataLoadPageFunction() {
    return (
        <>
            <Provider store={store}>
                <NavBarClass/>
                <PageComponents/>               
            </Provider>                 
        </>
    );
}

function buildApiPayload( expectedState){

    var payload = {};    
    payload['desiredState'] = expectedState    
    return payload;
}

function buildDataloadJobApiPayload(jobProgramName, selectedYear, selectedRound){
    
    var params = {};    
    params['year'] = selectedYear
    params['round'] = selectedRound
    return params;

    //callJOBApiPostDataload(path params)

}

function PageComponents(){    
    return (
        <>  
            <div className="pageoutlinecontainer container col-8 ">
                
                <div className="row ">
                    <div className="col pagetitlebarCol">
                        <PageTitleBar />
                    </div>
                </div>

                <div className="border border-secondary">

                <div className="row">
                    <div className="col raceHeadingCol">
                        <VMControlSection />                        
                    </div>
                </div>
                <hr className="mt-1 mb-1"/>
                <div className="row">
                    <div className="col raceHeadingCol">
                        <ProgramRunControlSection/>
                    </div>
                </div>
                <hr className="mt-1 mb-1"/>
                <div className="row">
                    <div className="col raceHeadingCol">
                        <RunHistoryRunSection/>
                    </div>
                </div>

                </div>                
            </div>
        </> 
    )
}

function PageTitleBar (){    
    return (
        <> 
            <div className="row rowPageTitle">
                <div className="col-3 backToAdminButtonSection">
                    <a href="/admin" className="btn btn-secondary btn-lg backToAdminButton" role="button">{'Admin'}</a>
                    
                </div>  
                <div className="col">
                    <label className="outlineTitleText d-none d-sm-block"> Table Load Admin </label> 
                </div>            
            </div> 
        </>        
    )
}

function VMControlSection (){
    
    return (
        <>
        <div className="container">        
            <div className="container col-lg-8 containerVmControlSection ">
                <div className="row rowVmControlSection">
                    <div className="col-6 colOnOffSlider">
                        <VmOnOffSlider label={vmState.VM_STATE}/>                        
                    </div>
                </div>      
                                      
            </div>     
            </div>    
        </>        
    )
}

async function syncVmStateWithEc2Status_V1(){

    const dispatch = useDispatch()

    var currentEC2State = await callApiGetDataload(buildApiPayload("N/A"))
    
    dispatch(setVmState(currentEC2State.data));

    const vmSliderState = currentEC2State.data

    

    if((vmSliderState == 'EC2_STARTING') || (vmSliderState == 'EC2_STARTED') ){
        document.getElementById("vmControlSwitch").checked = true;
    }
    else{
        document.getElementById("vmControlSwitch").checked = false;
    }
    

    return currentEC2State.data
}

async function syncVmStateWithEc2Status(dispatch){

    const currentEC2State = await callApiGetDataload(buildApiPayload("N/A"))
    dispatch(setVmState(currentEC2State.data));

    console.log(`running sync ${Date.now()}`)

    const vmSliderState = currentEC2State.data
    const switchElement = document.getElementById("vmControlSwitch");

    if (switchElement) {
        switchElement.checked = (vmSliderState === 'EC2_STARTING' || vmSliderState === 'EC2_STARTED');
    }
    

    return currentEC2State.data
}

function VmOnOffSlider ({label="Starting VM ...", onClick, disabled=false}){

    const dispatch = useDispatch()
    const vmSliderState = useSelector(selectVmState);

    const vmStateTextColor = {
        'EC2_STOPPED':'black',
        'EC2_STARTED':'green',
        'EC2_STARTING':'purple',
        'EC2_STOPPING':'pink'
    };

    const ec2State = syncVmStateWithEc2Status(dispatch)

    const textColor = { color: vmStateTextColor[label] || 'black' };
    const defaultChecked = vmSliderState === 'EC2_STARTING' || vmSliderState === 'EC2_STARTED';

    
    const vmOnOffSliderHandler = async () => {

        var isVmControlSwitchChecked = document.getElementById("vmControlSwitch").checked;
        const payload = buildApiPayload(isVmControlSwitchChecked ? "ON" : "OFF");

        const response = await callApiPostDataload(payload);
        dispatch(setVmState(response.data));
    };

    useEffect(() => {
        if (vmSliderState === "EC2_STARTING" || vmSliderState === "EC2_STOPPING") {
            const intervalId = setInterval(() => syncVmStateWithEc2Status(dispatch), 5000);            
            return () => clearInterval(intervalId);
        }
    }, [vmSliderState, dispatch]);
    
    return (
        <> 
            <div className="form-check form-switch sectionVmOnOff1">
                <input className="form-check-input switchVmOnOff" type="checkbox" disabled={disabled} id="vmControlSwitch" onClick={() => vmOnOffSliderHandler()} defaultChecked={defaultChecked}/>
                <label className="form-check-label labelVmOnOff d-none d-md-block " style={textColor} >{vmSliderState}</label>
            </div>
        </>
    )
}

async function launchDataloadJob(jobProgramName, selectedYear, selectedRound){

    console.log(`Processing Run Command job=${jobProgramName}, year=${selectedYear} and round=${selectedRound}`)
    //dataload_VM_EC2_REST
    var isVmControlSwitchChecked = document.getElementById("vmControlSwitch").checked;
    const payload = buildDataloadJobApiPayload(jobProgramName, selectedYear, selectedRound);
    var baba = await callJOBApiPostDataload(jobProgramName, payload);
    console.log(baba)
    //const response = await callApiPostDataload(payload);
    //dispatch(setVmState(response.data));

}

function ProgramRunControlSection (){

    var selectedJob = useSelector(selectDataloadJobState)
    var selectedYear= useSelector(selectDataloadYearState)
    var selectedRound = useSelector(selectDataloadRoundState)

    var ec2Status = useSelector(selectVmState)
    
    const dispatch = useDispatch()

    const selectedJobClickHandler = (title) => {
        
        const jobProgramName = jobs.find(job => job.displayName === title).programName;
        dispatch(setDataloadJobState(title))
    }
    const selectedYearClickHandler = (title) => {
        
        dispatch(setDataloadYearState(title))
    }
    const selectedRoundClickHandler = (title) => {
        
        dispatch(setDataloadRoundState(title))
    }

    const runProgramClickHandler = (title) => {        
        const jobProgramName = jobs.find(job => job.displayName === selectedJob).programName;      
        launchDataloadJob(jobProgramName, selectedYear, selectedRound)         

    }

    const isRunButtonDisabled = selectedJob === "Select Dataload Job" || selectedYear === "Select Dataload Year" || selectedRound === "Select Dataload Round"
                                                || ec2Status != "EC2_STARTED";

    

    return (        
        <div className="container-flex containerProgramRunControls">        
            <div className="row rowProgramSelectRun g-1">
                <div className="col">
                
                    <div className="row programSelectDropDown">
                        <GenericSelectDropDown label={selectedJob} items={jobs.map(job => job.displayName)} clickHandler={selectedJobClickHandler}/>   
                    </div> 
                    <div className="row dropDownGenericYear">
                        <GenericSelectDropDown label={selectedYear} items={years} clickHandler={selectedYearClickHandler}/>             
                    </div> 
                    <div className="row dropDownGenericRound">
                        <GenericSelectDropDown label={selectedRound} items={rounds} clickHandler={selectedRoundClickHandler}/>             
                    </div>
                </div> 
                <div className="col buttonProgramRun">
                    <ProgramRunButton onClick={runProgramClickHandler} disabled={isRunButtonDisabled}/>                            
                </div>
            </div>
        </div>     
    )
}

function GenericSelectDropDown({label="Year", items=[3,4,5,6], clickHandler}){

    
      
    return (
        <div className="btn-group ">
            <button className="btn btn-secondary dropdown-toggle programSelectionDropDown" type="button" data-bs-toggle="dropdown" aria-expanded="false" >
                {label}
            </button>
            
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                {items.map((item) => (
                    <Fragment key={item}>                        
                        <li><a className="dropdown-item" href="#" onClick={() => clickHandler(item)}>{item}</a></li>
                    </Fragment>
                ) )} 
            </ul>
        </div>
    )
}

function ProgramRunButton({label="Run", onClick, disabled=false}){   

    return (
        <>
            <button type="button" className="btn btn-primary btn-lg ButtonRunProgram" onClick={onClick} disabled={disabled}>{label}</button>
        </>
    )
}

function RunHistoryRunSection (){

    return (
        <> 
            <div className="container-flex tableRunHistory">
            <table className="table table-striped">
                <thead>
                    <tr>
                    <th scope="col">Program</th>
                    <th scope="col">last run date</th>
                    <th scope="col">State</th>
                    <th scope="col">{'Time(mins)'}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>load-race</td>
                    <td>22/09/2024 15:45</td>
                    <td>OK</td>
                    <td>25</td>
                    </tr>

                    <tr>
                    <td>load-race</td>
                    <td>22/09/2024 15:45</td>
                    <td>OK</td>
                    <td>25</td>
                    </tr>
                </tbody>
                </table>
                            
            </div>        
        </>        
    )
}
